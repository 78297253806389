export var SearchList = [
  // { labe: '公司', code: 'company', type: 'input' },
  {
    labe: '公司简称',
    code: 'code',
    type: 'select',
    option: [
      { label: '中科曙光', value: '1' }
    ]
  },
  { labe: '模板代码', code: '', type: 'input' },
  { labe: '模板说明', code: '', type: 'input' }
]
export var SearchData = {
  company: ''
}
export var tableField = [
  // {
  //   type: 'input',
  //   selectType: 'selection',
  //   width: '50',
  //   selectable: function (data) {
  //     return true
  //   }
  // },
  { label: '模板代码', code: 'mbDm', type: 'input', width: '' },
  { label: '模板说明', code: 'mbSm', type: 'input', width: '' },
  { label: '公司简称', code: 'gsJc', type: 'input', width: '' },
  { label: '考评类型', code: 'cpLx', type: 'input', width: '' },
  { label: '是否允许重复考评供应商', code: 'cpGys', type: 'amount', align: 'right', width: '160' },
  { label: '启用', code: 'enable', type: 'input', width: '' },
  {
    label: '操作',
    code: 'act',
    width: '',
    type: 'action',
    actionData: [
      {
        actionLabel: '新增',
        id: '1',
        stateField: 'judgeEdit',
        stateValue: '1'
      }
    ]
  }
]

export var tableData = [
  {
    mbDm: 'STANDARD_TEMPLATE01',
    mbSm: '中科曙光标准考评模板-单一类',
    gsJc: '中科曙光',
    cpLx: '标准考评',
    cpGys: '是',
    enable: true
  }
]
