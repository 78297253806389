<template>
  <div class="mainform toevtlist">
    <!-- 考评模板定义 -->
    <!-- <list-search @onSearch="onSearch" @clearSearch="clearSearch" :childData="childData"></list-search> -->
    <div class="form">
      <evaluate-model ref='goodslist' @getEvalData="getEvalData"></evaluate-model>
      <evaluatetable @pf="parent" :newParnData="newParnData"></evaluatetable>
    </div>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { SearchList, SearchData } from './js/evaluatetemplate'
import EvaluateModel from './EvaluateModel.vue'
// import { request } from '@/assets/js/http.js'
export default {
  // components: Component.components,
  components: { ...Component.components, EvaluateModel },
  name: 'Evaluatetemplate',
  data () {
    return {
      parentData: {},
      newParnData: {},
      childData: { searchList: SearchList, searchData: SearchData }
    }
  },
  mounted () {
    this.getEvalData()
  },
  methods: {
    // 指标组件调用模板组件中的方法
    parent () {
      console.log('123', this.$refs.goodslist)
      this.$refs.goodslist.getDataList()
    },

    // 获取对应指标数据
    getEvalData (data) {
      this.parentData = data
    },
    // 查询
    onSearch (data) {
      this.getDataList(data)
    },
    // 清空
    clearSearch (data) {
      this.getDataList(data)
    }
  },
  watch: {
    parentData (newData) {
      if (newData) {
        this.newParnData = newData
      }
    }
  }
}
</script>
<style scoped lang="scss">
  @import '@/assets/css/elForm.scss';
  @import '@/assets/css/tableList.scss';
  .mainform{
    :deep(.tableList){
      padding: 0px;
    }
  }
  .action{
    margin-top: 20px;
  }
  .toevtlist{
    .form{
      padding: 0 30px;
    }
  }
</style>
