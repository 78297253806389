<template>
  <div class="tableList toevtlist">
    <div class="header">模板
      <div class="exportData">
        <el-button class="textBtn" @click="AddRework" type="text">+新增</el-button>
      </div>
    </div>
    <div class="table">
      <el-table :data="tableData" ref="tabledeatailData" max-height="350" highlight-current-row @current-change="handleCurrentChange" style="width: 100%">
        <el-table-column prop="templateCode" label="模板代码" width="200px" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="templateName" label="模板说明" width="" :show-overflow-tooltip="true">
        </el-table-column>
<!--         <el-table-column prop="company" label="公司简称" width="100px" :show-overflow-tooltip="true"></el-table-column> -->
        <el-table-column prop="type" label="参评类型" width="100px" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="isRepeat" label="是否允许重复考评供应商" width="180px" :show-overflow-tooltip="true">
          <template #default="scope">
            <el-checkbox :disabled=scope.row.showDisabled v-model="scope.row.isRepeat"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="启用" width="70px" :show-overflow-tooltip="true">
          <template #default="scope">
            <el-checkbox :disabled=scope.row.showDisabled v-model="scope.row.status"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100px">
          <template #default="scope">
            <el-button v-if="scope.row.edStart === 1" type="text" size="mini" @click="edit(scope.row)">编辑</el-button>
            <el-button v-else type="text" size="mini" @click="preservation(scope.row)">保存</el-button>
            <el-button type="text" size="mini" @click="eliminateDinlog(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <dialog-box ref="AddNewData" componentName="EvaluateModelDialog" dialogWidth="800px" :dialogShow="AddcheckDialogShow" @handleClose='handleClose' title="新增" :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{label:'确认',action:'addPrework', type:'primary', size:'mini'}]" @addPrework="peworkBtn"></dialog-box>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { request } from '@/assets/js/http.js'
export default {
  components: Component.components,
  name: 'EvaluateModel',
  props: [''],
  data () {
    return {
      getModelData: {},
      AddcheckDialogShow: false,
      tableData: []
    }
  },
  created () {
    this.getDataList()
  },
  methods: {
    // 获取考评模板定义列表
    getDataList () {
      const obj = {
      }
      request('/api/evaluation/template/queryAll?pageNum=' + 1 + '&' + 'pageSize=' + 10000, 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.one.records
          this.tableData.forEach(item => {
            item.edStart = 1
            item.showDisabled = true
          })
          if (this.tableData[0].two) {
            this.getModelData = this.tableData[0]
            this.$emit('getEvalData', this.getModelData)
          }
        }
      })
    },

    // 新增弹框
    AddRework () {
      this.AddcheckDialogShow = true
    },
    // 点击新增里面的确认按钮
    peworkBtn () {
      var newsArr = this.$refs.AddNewData.$refs.content.form
      newsArr.edStart = 1
      newsArr.showDisabled = true
      if (!newsArr.templateCode) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请输入模板代码'
        })
        return false
      }
      if (!newsArr.templateName) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请输入模板说明'
        })
        return false
      }
      if (!newsArr.type) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请选择考评类型'
        })
        return false
      }
      if (!newsArr.company) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请选择公司'
        })
        return false
      }

      const obj = {
        parentId: this.parentId,
        templateCode: newsArr.templateCode,
        templateName: newsArr.templateName,
        type: newsArr.type,
        company: newsArr.company,
        isRepeat: newsArr.isRepeat,
        status: newsArr.status
      }
      request('/api/evaluation/template/save', 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.$message({
            showClose: true,
            type: 'success',
            message: '添加成功'
          })
          this.getDataList()
        }
      })
      this.AddcheckDialogShow = false
    },
    // 保存
    preservation (row) {
      const obj = {
        ...row
      }
      request('/api/evaluation/template/update', 'POST', obj).then((res) => {
        if (res.code === '200') {
          row.edStart = 1
          row.showDisabled = true
          this.$message({
            showClose: true,
            type: 'success',
            message: '修改成功'
          })
          this.getDataList()
        }
      })
    },
    // 编辑
    edit (row) {
      row.edStart = 2
      row.showDisabled = false
    },

    // 删除
    eliminate (row) {
      request('/api/evaluation/template/delete?id=' + row.id, 'post').then((res) => {
        if (res.code === '200') {
          this.$message({
            showClose: true,
            type: 'success',
            message: '删除成功'
          })
          this.getDataList()
        }
      })
    },
    // 删除弹框
    eliminateDinlog (row) {
      this.$confirm('确认要删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.eliminate(row)
      })
    },
    // 关闭弹窗
    handleClose () {
      this.AddcheckDialogShow = false
    },
    // 选中模板
    handleCurrentChange (val) {
      this.getModelData = val
      this.$emit('getEvalData', this.getModelData)
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/css/tableList.scss';
@import '@/assets/css/elForm.scss';
.toevtlist{
  .header{
    position: relative;
    .exportData{
      position: absolute;
      left: 60px;
      top: 0;
    }
  }
}
.tableList {
  .table{
    :deep(.el-table td) {
        padding: 12px 0;
    }
    :deep(.el-input__inner){
      height:34px;
      line-height: 34px;
    }
  }
  .is-disabled{
    :deep(.el-input__inner){
      background: none;
      border: none;
      color: #606266;
      padding: 0;
    }
    :deep(.el-select__caret){
      display: none;
    }
  }
}
</style>
